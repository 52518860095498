import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/glasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import CartContext from "../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )

  const right_side_text = "Every pair of our men’s glasses frames has impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush."
  const list_items = [
    "Quality first. Men’s eyeglasses at prices you can’t believe; anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every pair.",
    "Comfort Matters. With durable barrel hinges and padded temple tips for extra comfy cush.",
    "Personalized just-for-you. Ease your eyes—add blue-light filtering and reader magnification to any of our glasses frames for men.",
    "Made with care. Every pair of men’s glasses is made to order and hand-assembled.",
    "Designed for you. We understand that every man is unique and so are their vision needs. That’s why we’ve done the work of developing our signature collection of styles developed in-house. Shop by frame width, shape and color, or browse our whole selection to find your perfect pair."
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: ["male"],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical-Men`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [{
    "@url": "https://hubblecontacts.com/glasseshttps://hubblecontacts.com/mens-glasses",
    "@type": "webpage",
    "name": "Mens Glasses: Prescription Eyeglasses for Men | Hubble",
    "description": "Shop our large collection of mens glasses and frames online. With Hubble's wide range of prescription mens glasses you're guaranteed to find a pair that fits your unique style.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout className="glasses">
      <SEO
        title="Mens Glasses: Prescription Eyeglasses for Men"
        description="Shop our large collection of mens glasses and frames online. With Hubble's wide range of prescription mens glasses you're guaranteed to find a pair that fits your unique style."
        schemaMarkup={schemaMarkup} />
      <GlassesHeading
        color="orange"
        title="Men's Glasses"
        description="Handcrafted men’s prescription glasses starting at just $49.99. Our curated
                      collection of glasses frames for men are high-quality and deliver the look and
                      performance you want. Whether you're looking for a classic, timeless pair of
                      men’s glasses or a more modern, trendy design, we've got you covered."
      />

      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"The best glasses for men starting at just $49.99."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
